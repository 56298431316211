import React from 'react';
import './Tagline.css'

function Tagline() {
    return (
      <div className="hero-tagline">
        <h1>A solo developer's journey to impact the world</h1>
        <h2>One <span className="colored-text">Challenge</span> at a Time.</h2>
      </div>
    );
}
  

export default Tagline;
