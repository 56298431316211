import React from 'react';
import NavBar from '../components/Fixed/NavBar';
import Hero from '../components/LandingPage/Hero';
import Simulation from '../components/LandingPage/HelloLife/Simulation';
import UserAuth from '../components/LandingPage/UserAuth/UserAuth';
import Footer from '../components/Fixed/Footer';

function LandingPage() {
  return (
    <div className="landing-page">
      <NavBar />
      <Hero />
      <Simulation />
      <UserAuth />
      <Footer />
    </div>
  );
}

export default LandingPage;
