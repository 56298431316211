import React, { useState } from 'react';
import './ProfileInformation.css'; // Make sure to define your styles in ProfileInformation.css
import settingsButton from '../../assets/settingsButton.png'
import pencilButton from '../../assets/pencilButton.png'
import axios from 'axios'; // Assuming you're using axios for API calls
import { config } from '../../config'; // Adjust the path as needed


const API_URL = config.API_URL;


const ProfileInformation = ({ currentLevel, birthstone, country, workField, bio, colorClass, colorValue, allowEdit, updateUserData }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editableData, setEditableData] = useState({ currentLevel, birthstone, country, workField, bio });
  
  
  const countryOptions = ["Unknown","United States", "Canada","Australia", "United Kingdom", "India", "Germany", "Pakistan", "Romania"];
  const birthstoneOptions = ["Unknown",'January','February', 'March', 'April','May','June', 'July','August','September','October','November','December']
  const workfieldOptions = ["Unknown",'Corporate','Skilled Labor','Creative','Freelancer','Outdoor Activities','Healthcare','Technology','Education','Service Industry','Retail Industry','Public Service','Student','Retired','Other']

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableData(prevData => ({ ...prevData, [name]: value }));
  };

  const saveEdits = async () => {
    const token = localStorage.getItem('token');

    if (!token) {
        console.error('No token found in localStorage');
        return;
    }

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    };

    try {
      setIsEditing(false); // Disable editing mode
      const response = await axios.post(`${API_URL}/insert-profile-attributes`, editableData, requestOptions); // Replace with your actual API endpoint
      const updatedProfile = response.data.updatedProfile;

      // Update the component's state with the new data
      updateUserData(updatedProfile);
      
    } catch (error) {
      console.error('Error updating profile:', error);
      // Optionally handle the error, e.g., show an error message to the user
    }
  };

  return (
    <div className={`profile-information-holding-container`} style={{borderColor: colorValue}}>
      <div className="header-container">
        
        {allowEdit === 1 && !isEditing && (
        <>
          <img src={pencilButton} alt="Edit" className="pencil-button" onClick={handleEditClick} />
          <img src={settingsButton} alt="Edit" className="edit-button" onClick={handleEditClick} />
        </>
      )}
        
      </div>
      <table className={`profile-information-table ${colorClass}`} >
        <tbody>
          <tr>
            <th className={`row-header ${colorClass}`}>Current Level</th>
            <td className='row-content'>{currentLevel}</td>
          </tr>
          <tr>
            <th className={`row-header ${colorClass}`}>Birthstone</th>
            <td className='row-content'>
              {isEditing ? (
                  <select name="birthstone" value={editableData.birthstone} onChange={handleInputChange} style={{borderColor:colorValue}}>
                    {birthstoneOptions.map(birthstone => (
                      <option key={birthstone} value={birthstone}>{birthstone}</option>
                    ))}
                  </select>
                ) : birthstone}
              </td>
          </tr>
          <tr>
            <th className={`row-header ${colorClass}`}>Country</th>
            <td className='row-content'>
              {isEditing ? (
                <select name="country" value={editableData.country} onChange={handleInputChange} style={{borderColor:colorValue}}>
                  {countryOptions.map(country => (
                    <option key={country} value={country}>{country}</option>
                  ))}
                </select>
              ) : country}
            </td>
          </tr>

          <tr>
            <th className={`row-header ${colorClass}`}>Field of Work</th>
            <td className='row-content'>
              {isEditing ? (
                <select name="workField" value={editableData.workField} onChange={handleInputChange} style={{borderColor:colorValue}}>
                  {workfieldOptions.map(workField => (
                    <option key={workField} value={workField}>{workField}</option>
                  ))}
                </select>
              ) : workField}
              </td>
          </tr>
        </tbody>
      </table>
      <div className="profile-bio">
        {isEditing ? (
          <textarea
            id="bio"
            className="bio-box"
            style={{ borderColor: colorValue }}
            name="bio"
            value={editableData.bio}
            onChange={handleInputChange}
          />
        ) : (
          <div id="bio" className="bio-box" style={{ borderColor: colorValue }}>
            {bio}
          </div>
        )}
      </div>


      <div className='action-buttons'>
      {isEditing && <button onClick={saveEdits}>Save</button>}
      {isEditing && <button onClick={handleCancelClick}>Cancel</button>}
      </div>

    </div>
  );
};

export default ProfileInformation;
