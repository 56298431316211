import React from 'react';
import Tagline from './Tagline';
import Illustration from './Illustration';
import './index.css'

function Hero() {
  return (
    <div className="hero-section">
      <Tagline />
      <Illustration />
    </div>
  );
}

export default Hero;
