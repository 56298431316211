import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const PublicRoute = ({ children }) => {
    const token = localStorage.getItem('token');
    let isAuthenticated = false;

    if (token) {
        try {
            const decoded = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            isAuthenticated = decoded.exp > currentTime;
        } catch (error) {
            isAuthenticated = false;
        }
    }

    // If authenticated, redirect to the home page
    return isAuthenticated ? <Navigate to="/home" replace /> : children;
};

export default PublicRoute;
