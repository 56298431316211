import React from 'react';
import NavBar from '../components/Fixed/NavBar';
import Footer from '../components/Fixed/Footer';
import PasswordReset from '../components/ForgotPassword/PasswordReset';

const PasswordResetPage = () => {
  
  return (
    <div className="landing-page">
      <NavBar />
      <PasswordReset />
      <Footer />
    </div>
  );
};

export default PasswordResetPage;
