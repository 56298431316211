import React from 'react';
import NavBar from '../components/Fixed/NavBar';
import Footer from '../components/Fixed/Footer';
import ForgotPassword from '../components/ForgotPassword/ForgotPassword';

const ForgotPasswordPage = () => {
  
  return (
    <div className="landing-page">
      <NavBar />
      <ForgotPassword />
      <Footer />
    </div>
  );
};

export default ForgotPasswordPage;
