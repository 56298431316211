import React, { useState, useEffect, useRef } from 'react';
import mainLogo from '../../assets/mainLogo.png';
import navBar_dropDown from '../../assets/navBar_dropDown.png'
import './NavBar.css';
import { useNavigate } from 'react-router-dom';

function NavBar({ username }) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  

  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate(`/home`)
  }

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className="navbar">
      <img src={mainLogo} alt="Main Logo" className="main-logo" onClick={handleLogoClick} />

      <div className="menu" ref={ref}>
        <img src={navBar_dropDown} alt='menu' onClick={toggleOpen} />
        {isOpen && (
          <div className="dropdownMenu">
            <a href="/home">Home</a>
            <a href="/wall">Motivation Wall</a>
            <a href="/leaderboard">Player Leaderboard</a>
            <a href="/game-guide">Game Guide</a>
            <a href={`/user-profile/${username}`}>My Profile</a>
          </div>
        )}
      </div>
    </div>
  );
}

export default NavBar;
