import React, { useEffect, useState } from 'react';
import './UserProfileParent.css';

import UncommonCrown from '../../assets/CrownRanks/UncommonCrown.png';
import CommonCrown from '../../assets/CrownRanks/CommonCrown.png';
import RareCrown from '../../assets/CrownRanks/RareCrown.png';
import EpicCrown from '../../assets/CrownRanks/EpicCrown.png';
import LegendaryCrown from '../../assets/CrownRanks/LegendaryCrown.png'
import MythicalCrown from '../../assets/CrownRanks/MythicalCrown.png'

import WelcomeCard from './WelcomeCard';
import ProfileInformation from './ProfileInformation';
import UserProfileMetrics from './UserProfileMetrics';
import COLOREDUserProfileCard from './COLOREDUserProfileCard/COLOREDUserProfileCard';
import UserMessageCard from '../HomePage/UserMessageCard';
import { config } from '../../config'; // Adjust the path as needed


const API_URL = config.API_URL;

const UserProfile = ({ username }) => {
    const [userData, setUserData] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
          const token = localStorage.getItem('token');
  
          if (!token) {
              console.error('No token found in localStorage');
              return;
          }
  
          const requestOptions = {
              method: 'GET',
              headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json'
              }
          };
          
          try {
              // Include requestOptions in the fetch call
              const response = await fetch(`${API_URL}/user-profile/${username}`, requestOptions);
              if (!response.ok) {
                  throw new Error('Network response was not ok');
              }
              const data = await response.json();
              setUserData(data);
          } catch (error) {
              console.error('Fetch error:', error);
          }
      };
  
      fetchData();
  }, [username]); // Dependency array ensures this runs only when username changes
  

    // Check if userData is loaded
    if (!userData) {
        return <div>Loading...</div>;
    }

    
    
    let crownSrc;
    let colorClass;
    let colorValue;


    // Payload using username to find currentLevel
    if (userData.totalScores.currentLevel <= 7) {
      crownSrc = null;
      colorClass = 'no-shade';
      colorValue = '#DCCACA';
    } else if (userData.totalScores.currentLevel > 7 && userData.totalScores.currentLevel <= 28) {
      crownSrc = UncommonCrown;
      colorClass = 'uncommon-shade';
      colorValue = '#A0A0A0';

    } else if (userData.totalScores.currentLevel > 28 && userData.totalScores.currentLevel <= 56) {
      crownSrc = CommonCrown;
      colorClass = 'common-shade';
      colorValue = '#00C62C';
    } else if (userData.totalScores.currentLevel > 56 && userData.totalScores.currentLevel <= 140) {
      crownSrc = RareCrown;
      colorClass = 'rare-shade';
      colorValue = '#0050C9';
    } else if (userData.totalScores.currentLevel > 140 && userData.totalScores.currentLevel <= 224) {
      crownSrc = EpicCrown;
      colorClass = 'epic-shade';
      colorValue = '#5C01B7'
    } else if (userData.totalScores.currentLevel > 224 && userData.totalScores.currentLevel <= 308) {
      crownSrc = MythicalCrown;
      colorClass = 'mythical-shade';
      colorValue = '#00A1E5'
    } else if (userData.totalScores.currentLevel > 308) {
      crownSrc = LegendaryCrown;
      colorClass = 'legendary-shade';
      colorValue = '#FE8900'
    }


    const updateUserData = (updatedProfile) => {
      setUserData(prevData => ({
        ...prevData,
        totalScores: {
          ...prevData.totalScores,
          ...updatedProfile
        }
      }));
    };

   

    return (
      <div className='user-profile-holding-box'>
      

            <div className="user-profile-welcome" style={{borderColor: colorValue}}>
                <WelcomeCard 
                    username={username} 
                    colorClass={colorClass} 
                    crownSrc={crownSrc}
                    colorValue={colorValue}>
                </WelcomeCard>
            </div>

            <div className='user-profile-metrics'>
                <UserProfileMetrics
                    averageWinPercent={userData.totalScores.averageWinPercent}
                    lifetimePoints={userData.totalScores.lifetimePoints}
                    lifetimeChallenges={userData.totalScores.lifetimeChallenges}
                    daysSinceSignup={userData.totalScores.daysSinceSignup}
                    colorValue={colorValue}
                
                  >

                </UserProfileMetrics>


            </div>


            <div className='user-description'>
                <ProfileInformation 
                    username={username}
                    currentLevel={userData.totalScores.currentLevel}
                    birthstone={userData.totalScores.birthstone}
                    country={userData.totalScores.country}
                    workField={userData.totalScores.workField}
                    bio={userData.totalScores.bio}
                    colorClass={colorClass}
                    colorValue={colorValue}
                    allowEdit={userData.totalScores.allowEdit}
                    updateUserData={updateUserData}>
                </ProfileInformation>
            </div>

            <div className='user-weekly-messages'>
              {userData.userWeeklyMessages && userData.userWeeklyMessages.map((message, index) => (
                <UserMessageCard
                  key={message.Id} // Unique key for each message, using the ID
                  username={message.USERNAME}
                  datetime={message.DATETIME}
                  message={message.MESSAGE}
                  currentLevel={message.CURRENT_LEVEL}
                  colorValue={colorValue}
                />
              ))}
            </div>

            <div className='user-weekly-score'>
              <COLOREDUserProfileCard 
                username={userData.weeklyScores.username} 
                currentLevel={userData.weeklyScores.currentLevel}
                weeklyPoints={userData.weeklyScores.weeklyPoints} 
                weeklyChallenges={userData.weeklyScores.weeklyChallenges}
                averageWinPercent={userData.weeklyScores.averageWinPercent} 
                currentStreak={userData.weeklyScores.currentStreak}
                currentXP={userData.weeklyScores.currentXP}
                dataForVisualization={userData.data}
                colorValue={colorValue}
                />
            </div>

            

      </div>
  
  );
  
    
    
    
};

export default UserProfile;
