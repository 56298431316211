import React  from 'react';
import SandboxLogo from '../../assets/SandboxLogo.png'
import './Footer.css'



function Footer() {

    

    return (
      <div className="footer">
        <div className="top-section">
          <img src={SandboxLogo} alt="Sandbox Computing Logo" className="sandbox-logo" />
          <h1 className='company-name'>Sandbox Computing</h1>
        </div>
        <h2 className='tagline'>Where Imagination Meets Reality.</h2>
      </div>
    );
  }
  

export default Footer;
