import React from 'react';
import './LeaderboardTable.css'; // Assuming you have a CSS file for styling
import { useNavigate } from 'react-router-dom';
import UncommonCrown from '../../assets/CrownRanks/UncommonCrown.png';
import CommonCrown from '../../assets/CrownRanks/CommonCrown.png';
import RareCrown from '../../assets/CrownRanks/RareCrown.png';
import EpicCrown from '../../assets/CrownRanks/EpicCrown.png';
import LegendaryCrown from '../../assets/CrownRanks/LegendaryCrown.png'
import MythicalCrown from '../../assets/CrownRanks/MythicalCrown.png'


const LeaderboardTable = ({ leaderboardData }) => {
    const navigate = useNavigate();

    const handleUsernameClick = (username) => {
        navigate(`/user-profile/${username}`)
      }

    return (
        <table className="leaderboard-table-sub-component">
            <thead>
                <tr className="header-row">
                    <th className="header-rank">Rank</th>
                    <th className="header-username">Username</th>
                    <th className="header-level">Level</th>
                    <th className="header-points">Points Earned</th>
                    <th className="header-country">Country</th>
                    <th className="header-birthstone">Birthstone</th>
                    <th className="header-field">Work Field</th>
                </tr>
            </thead>
            <tbody>
                {leaderboardData.map((entry, index) => {
                    let crownSrc, colorClass, colorValue;

                    // Apply logic to each entry
                    if (entry.CURRENT_LEVEL <= 7) {
                        crownSrc = null;
                        colorClass = 'no-shade';
                        colorValue = '#DCCACA';
                    } else if (entry.CURRENT_LEVEL > 7 && entry.CURRENT_LEVEL <= 28) {
                    crownSrc = UncommonCrown;
                    colorClass = 'uncommon-shade';
                    colorValue = '#A0A0A0';
                
                    } else if (entry.CURRENT_LEVEL > 28 && entry.CURRENT_LEVEL <= 56) {
                    crownSrc = CommonCrown;
                    colorClass = 'common-shade';
                    colorValue = '#00C62C';
                    } else if (entry.CURRENT_LEVEL > 56 && entry.CURRENT_LEVEL <= 140) {
                    crownSrc = RareCrown;
                    colorClass = 'rare-shade';
                    colorValue = '#0050C9';
                    } else if (entry.CURRENT_LEVEL > 140 && entry.CURRENT_LEVEL <= 224) {
                    crownSrc = EpicCrown;
                    colorClass = 'epic-shade';
                    colorValue = '#5C01B7'
                    } else if (entry.CURRENT_LEVEL > 224 && entry.CURRENT_LEVEL <= 308) {
                    crownSrc = MythicalCrown;
                    colorClass = 'mythical-shade';
                    colorValue = '#00A1E5'
                    } else if (entry.CURRENT_LEVEL > 308) {
                    crownSrc = LegendaryCrown;
                    colorClass = 'legendary-shade';
                    colorValue = '#FE8900'
                    }

                    return (
                        <tr key={entry.USER_ID} className={`row-${index % 2 === 0 ? 'even' : 'odd'}`}>
                            <td className="cell-rank">{index + 1}</td>
                            <td className={`cell-username ${colorClass}`} onClick={() => handleUsernameClick(entry.USERNAME)}>
                                {crownSrc && <img src={crownSrc} alt="Crown" style={{ marginRight: '5px' }}/>}
                                <span style={{ color: colorValue }}>{entry.USERNAME}</span>
                            </td>
                            <td className="cell-level">{entry.CURRENT_LEVEL}</td>
                            <td className="cell-points">{entry.POINTS_EARNED}</td>
                            <td className="cell-country">{entry.COUNTRY}</td>
                            <td className="cell-birthstone">{entry.BIRTHSTONE}</td>
                            <td className="cell-field">{entry.WORK_FIELD}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default LeaderboardTable;

