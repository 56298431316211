import { config } from '../../config'; // Adjust the path as needed

const API_URL = config.API_URL;


async function fetchHomePayload() {
    const token = localStorage.getItem('token');

    if (!token) {
        console.error('No token found in localStorage');
        return;
    }

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    };

    try {
        const response = await fetch(`${API_URL}/home-payload-refactor`, requestOptions);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching home payload:', error);
    }
}

export default fetchHomePayload;

