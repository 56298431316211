import React, { useEffect, useState } from 'react';
import NavBar from '../components/Fixed/NavBar';
import Footer from '../components/Fixed/Footer';
import UserWallFeed from '../components/TheWall/UserWallFeed';
import pagePayload from './pagePayload';


function TheWall() {

  const [staticUsername, setStaticUsername] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const data = await pagePayload();
      setStaticUsername(data.username);
    };

    fetchData();
  }, []);


  

  return (
    <div className="landing-page">
      <NavBar username={staticUsername}/>

      <UserWallFeed />

    
      <Footer />
    </div>
  );
}

export default TheWall;
