import React from 'react';
import './UserExperienceBar.css';
import UncommonCrown from '../../../assets/CrownRanks/UncommonCrown.png';
import CommonCrown from '../../../assets/CrownRanks/CommonCrown.png';
import RareCrown from '../../../assets/CrownRanks/RareCrown.png';
import EpicCrown from '../../../assets/CrownRanks/EpicCrown.png';
import LegendaryCrown from '../../../assets/CrownRanks/LegendaryCrown.png'
import MythicalCrown from '../../../assets/CrownRanks/MythicalCrown.png'


function UserExperienceBar({ currentLevel, currentXP }) {
    const progressBarWidth = `${currentXP * 100}%`;
    let lineColor;
    let nextRankLevel;
    let nextRankCrown;
    
    
    if (currentLevel <= 7) {
        lineColor = "#DCCACA";
        nextRankLevel = 7;
        nextRankCrown = UncommonCrown;
        
    } else if (currentLevel > 7 && currentLevel <= 28) {
        lineColor = "#A0A0A0";
        nextRankLevel = 28;
        nextRankCrown = CommonCrown;
        
    } else if (currentLevel > 28 && currentLevel <= 56) {
        lineColor = "#00C62C";
        nextRankLevel = 56;
        nextRankCrown = RareCrown;
        
    } else if (currentLevel > 56 && currentLevel <= 140) {
        lineColor = "#0050C9";
        nextRankLevel = 140;
        nextRankCrown = EpicCrown;
        
    } else if (currentLevel > 140 && currentLevel <= 224) {
        lineColor = "#5C01B7";
        nextRankLevel = 224;
        nextRankCrown = MythicalCrown;
       
    } else if (currentLevel > 224 && currentLevel <= 308) {
        lineColor = "#00A1E5";
        nextRankLevel = 308;
        nextRankCrown = LegendaryCrown
        
    } else {
        lineColor = "#FE8900";
        nextRankLevel = currentLevel; // Or some maximum level if there's a cap
    }

    const levelsUntilNextRank = nextRankLevel - currentLevel;

    return (
        <div className="experience-bar-container">
            <div className="level-display" style={{color: lineColor}}>Level {currentLevel}</div>
            <div className="progress-bar" style={{borderStyle: 'solid', borderColor: lineColor, borderWidth: '2px'}}>
                <div className="progress-bar-filled" style={{ width: progressBarWidth, backgroundColor: lineColor}}></div>
            </div>
            {nextRankLevel > currentLevel && 
                <div className="levels-until-next-rank">
                
                Don't stop! Keep going! {levelsUntilNextRank} levels to go!
                <img src={nextRankCrown} alt="Next Rank Crown" className="next-rank-crown" />
                </div>
            }
        </div>
    );
}

export default UserExperienceBar;

