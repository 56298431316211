import React, { useEffect, useState } from 'react';
import NavBar from '../components/Fixed/NavBar';
import Footer from '../components/Fixed/Footer';
import GameWeekList from '../components/GameGuide/GameWeekList';
import pagePayload from './pagePayload';


function GameGuide() {
  const [staticUsername, setStaticUsername] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const data = await pagePayload();
      setStaticUsername(data.username);
    };

    fetchData();
  }, []);

  return (
    <div className="landing-page">
      <NavBar username={staticUsername}/>

      <GameWeekList />
     
    
      <Footer />
    </div>
  );
}

export default GameGuide;
