import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import './WelcomeCard.css';
import { config } from '../../config'; // Adjust the path as needed
import UserListModal from './UserListModal';

const API_URL = config.API_URL;

const WelcomeCard = ({ username, crownSrc, colorClass, colorValue }) => {
  const [socialStats, setSocialStats] = useState({ 
    followersCount: 0, 
    followingCount: 0, 
    allowFollow: 0,
    isFollowingFlag: 0
  });

  

  const fetchSocialStats = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found in localStorage');
      return;
    }
    const requestOptions = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    try {
      const response = await axios.get(`${API_URL}/fetch-social-stats/${username}`, requestOptions);
      setSocialStats(response.data);
    } catch (error) {
      console.error('Error fetching followers:', error.response?.data?.error || error.message);
    }
  }, [username]);;

  const handleFollow = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found in localStorage');
      return;
    }
    try {
      await axios.post(`${API_URL}/follow-user/${username}`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      setSocialStats(prevStats => ({ ...prevStats, followersCount: prevStats.followersCount + 1 }));
      fetchSocialStats();
    } catch (error) {
      console.error('Error following user:', error.response?.data?.error || error.message);
    }
  };

  const handleUnfollow = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found in localStorage');
      return;
    }
    try {
      await axios.post(`${API_URL}/unfollow-user/${username}`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      setSocialStats(prevStats => ({ ...prevStats, followersCount: prevStats.followersCount - 1 }));
      fetchSocialStats();
    } catch (error) {
      console.error('Error following user:', error.response?.data?.error || error.message);
    }
  };
  

  useEffect(() => {
    fetchSocialStats();
  }, [username, fetchSocialStats]);

  const { followersCount, followingCount, allowFollow, isFollowingFlag } = socialStats;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userList, setUserList] = useState([]);

  const fetchUsers = async (type) => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found in localStorage');
      return;
    }
    const requestOptions = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    try {
      const response = await axios.get(`${API_URL}/fetch-user-${type}/${username}`, requestOptions);
      setUserList(response.data[type]); // Assuming the response has a key matching the type (followers or following)
    } catch (error) {
      console.error(`Error fetching ${type}:`, error.response?.data?.error || error.message);
    }
  };
  

  const openModal = (type) => {
    setIsModalOpen(true);
    fetchUsers(type);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setUserList([]);
  };


  return (
    <div className="welcome-card">
      {crownSrc && <img src={crownSrc} alt="Crown" className="profile-crown" />}
      <h2 className={`username ${colorClass}`}>
        <span>{username}</span>
      </h2>
      <div className="social-info">
        <div className="followers" onClick={() => openModal('followers')}>
          <span className="count">{followersCount}</span>
          <span className="label">Followers</span>
        </div>
        <div className="following" onClick={() => openModal('following')}>
          <span className="count">{followingCount}</span>
          <span className="label">Following</span>
        </div>
      </div>

      {allowFollow === 1 && isFollowingFlag === 0 && 
          <button className="follow-btn" onClick={handleFollow} style={{borderColor: colorValue, backgroundColor:colorValue}}>Follow!</button>}
      {allowFollow === 1 && isFollowingFlag === 1 && 
          <button className="unfollow-btn" onClick={handleUnfollow} style={{borderColor: colorValue}}>Unfollow</button>}

      <UserListModal isOpen={isModalOpen} users={userList} onClose={closeModal} />
    </div>
  );
};

export default WelcomeCard;
