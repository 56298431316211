import React, { useState, useCallback, useEffect } from "react";
import SelectBox from './SelectBox';
import AdvancedFilterModal from './AdvancedFilterModal';
import './LeaderboardParent.css';
import { config } from '../../config'; // Adjust the path as needed
import axios from 'axios'; // Make sure to install axios
import LeaderboardTable from "./LeaderboardTable";

const API_URL = config.API_URL;


const LeaderboardParent = () => {
    const [socialFilter, setSocialFilter] = useState("community");
    const [dateFilter, setDateFilter] = useState("today");
    const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedBirthstones, setSelectedBirthstones] = useState([]);
    const [selectedWorkfields, setSelectedWorkfields] = useState([]);

    const [leaderboardData, setLeaderboardData] = useState([]);
    const [communityUsers, setCommunityUsers] = useState(0);
    const [friendsUsers, setFriendsUsers] = useState(0);

    const countryOptions = ["United States", "Canada", "Australia", "United Kingdom", "India", "Germany", "Pakistan", "Romania"];
    const birthstoneOptions = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    const workfieldOptions = ['Corporate', 'Skilled Labor', 'Creative', 'Freelancer', 'Healthcare', 'Technology', 'Education', 'Service Industry', 'Retail Industry', 'Public Service', 'Student', 'Retired', 'Other']

    const fetchLeaderboardData = useCallback(async () => {
        const token = localStorage.getItem('token');
  
        if (!token) {
            console.error('No token found in localStorage');
            return;
        }

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        let params = new URLSearchParams();
    
        // Add socialFilter and dateFilter
        params.append('socialFilter', socialFilter);
        params.append('dateFilter', dateFilter);
    
        // Function to add array filters
        const addArrayFilters = (paramName, filterArray) => {
            if (filterArray.length > 0) {
                let values = filterArray.map(item => item.value).join(',');
                params.append(paramName, values);
            }
        }
    
        // Add advanced filters
        addArrayFilters('countries', selectedCountries);
        addArrayFilters('birthstones', selectedBirthstones);
        addArrayFilters('workfields', selectedWorkfields);
    
        let queryString = params.toString();
    
        try {
            // Replace 'your-backend-endpoint' with the actual backend endpoint
            const response = await axios.get(`${API_URL}/fetch-leaderboard?${queryString}`, requestOptions);
            setLeaderboardData(response.data.leaderboardData);
            setCommunityUsers(response.data.communityActiveUsers);
            setFriendsUsers(response.data.friendsActiveUsers)
            
            
        } catch (error) {
            console.error('Error fetching leaderboard data:', error);
        }
    }, [socialFilter, dateFilter, selectedCountries, selectedBirthstones, selectedWorkfields]);

    useEffect(() => {
        fetchLeaderboardData();
    }, [fetchLeaderboardData]); // Empty dependency array ensures it runs only once on mount
    
    
    const handleSocialFilterChange = useCallback((filter) => {
        setSocialFilter(filter);
    }, []);

    const handleDateFilterChange = useCallback((filter) => {
        setDateFilter(filter);
    }, []);

    const toggleAdvancedFilters = useCallback(() => {
        setShowAdvancedFilters(prev => !prev);
    }, []);

    const applyAdvancedFilters = useCallback(() => {
        fetchLeaderboardData();
    }, [fetchLeaderboardData]);
    
    
    
    
    return (
        <div className="leaderboard-holding-container">
            <div className="leaderboard-main-header">Champions are built on a thousand invisible mornings.</div>
            <div className="leaderboard-sub-header">Don't Stop, Keep Going.</div>
            <div className='leaderboard-tag-line'>Are you being out competed?</div>

            <div className="leaderboard-controls">
                <div className="leaderboard-social-select-boxes">
                    <SelectBox title="Community" isSelected={socialFilter === "community"} onFilterChange={handleSocialFilterChange} valueCount={communityUsers} valueHeader={"Users Today!"} />
                    <SelectBox title="Friends" isSelected={socialFilter === "friends"} onFilterChange={handleSocialFilterChange} valueCount={friendsUsers} valueHeader={"Users Today!"} />
                </div>
                <div className="leaderboard-date-range-select-boxes">
                    <SelectBox title="Today" isSelected={dateFilter === "today"} onFilterChange={handleDateFilterChange} />
                    <SelectBox title="7-Day" isSelected={dateFilter === "7-day"} onFilterChange={handleDateFilterChange} />
                    <SelectBox title="Lifetime" isSelected={dateFilter === "lifetime"} onFilterChange={handleDateFilterChange} />
                </div>
                <button className="advanced-filter-toggle" onClick={toggleAdvancedFilters}>
                    {showAdvancedFilters ? 'Hide Advanced Filters' : 'Show Advanced Filters'}
                </button>

                {showAdvancedFilters && (
                    <AdvancedFilterModal
                        countryOptions={countryOptions}
                        birthstoneOptions={birthstoneOptions}
                        workfieldOptions={workfieldOptions}
                        onApplyFilters={applyAdvancedFilters}
                        selectedCountries={selectedCountries}
                        setSelectedCountries={setSelectedCountries}
                        selectedBirthstones={selectedBirthstones}
                        setSelectedBirthstones={setSelectedBirthstones}
                        selectedWorkfields={selectedWorkfields}
                        setSelectedWorkfields={setSelectedWorkfields}
                    />
                )}
            </div>

            <div className="leaderboard-table">
                <LeaderboardTable leaderboardData={leaderboardData} />
            </div>

            
        </div>
    );
};

export default LeaderboardParent;
