import React from 'react';
// Assuming you've added the illustration as an asset
import mainPicture from '../../../assets/mainPicture.png'
import './Illustration.css'

function Illustration() {
  return (
    <img src={mainPicture} alt="Wizard working on a laptop" className="hero-illustration" />
  );
}

export default Illustration;
