import { useState, useEffect, useCallback } from 'react';

export const useSimulationLogic = (initialIntervalTime = 1000, input_discipline, input_focus) => {
  const [simIndex, setSimIndex] = useState(0);
  const [simulationData, setSimulationData] = useState([]);
  const [intervalTime, setIntervalTime] = useState(initialIntervalTime);
  
 

  const simulateDay = useCallback((disciplineFactor, focusFactor) => {
    let firstStepScore = 0, secondStepScore = 0;
    for (let i = 0; i < 24; i++) {
      if (Math.random() < (0.5 + disciplineFactor * 0.5)) firstStepScore++;
    }
    for (let i = 0; i < firstStepScore; i++) {
      if (Math.random() < (0.5 + focusFactor * 0.5)) secondStepScore++;
    }
    return { simulation_num: simIndex + 1, awareness_factor: disciplineFactor, true_value: firstStepScore, perceived_value: secondStepScore };
  }, [simIndex]);

  useEffect(() => {
    if (simulationData.length > 0) {
      
    }
  }, [simulationData]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSimIndex(prevIndex => prevIndex + 1);
      const newMinDataPoint = simulateDay(0, 0);
      const newMaxDataPoint = simulateDay(.80, .80);

      const minTrueValue = newMinDataPoint.true_value;
      const minPerceivedValue = newMinDataPoint.perceived_value;
      const maxTrueValue = newMaxDataPoint.true_value;
      const maxPerceivedValue = newMaxDataPoint.perceived_value;
      const simulationNumber = simIndex + 1;
      const newSimulationData = {
        simulationNumber,
        minTrueValue,
        minPerceivedValue,
        maxTrueValue,
        maxPerceivedValue
      };
      setSimulationData(prevData => {
        const updatedData = [...prevData, newSimulationData];
        // If the array length exceeds 30, remove the first element
        if (updatedData.length > 30) {
          updatedData.shift(); // Removes the first element
        }
        return updatedData;
      });
    }, intervalTime);

    return () => clearInterval(intervalId);
  }, [intervalTime, simulateDay, simIndex, input_discipline, input_focus]);

  return { simulationData, setIntervalTime };
};
