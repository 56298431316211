import React, { useState, useEffect, useCallback } from "react";
import './UserWallFeed.css';

import UserSocialPost from "./UserSocialPost";

import { config } from '../../config'; // Adjust the path as needed

const API_URL = config.API_URL;



const UserWallFeed = () => {

    const [charCount, setCharCount] = useState(0);
    const [postText, setPostText] = useState('');


    const handleInputChange = (event) => {
        setPostText(event.target.value);
        setCharCount(event.target.value.length);
    };
    


    const [userWallData, setUserWallData] = useState({
        communityMessages: 0,
        friendsMessages: 0,
        userWallFeed: [],
        userDailyMessage: null,
        userPostFlag: 0
    });

    const [socialFilter, setSocialFilter] = useState("community"); // Social filter state

    const token = localStorage.getItem('token');

    const fetchWallFeed = useCallback(async (filter = "community") => {
        if (!token) {
            console.error('No token found in localStorage');
            return;
        }

        const url = new URL(`${API_URL}/fetch-wall-feed`);
        url.searchParams.append('socialFilter', filter); // Append filter as a query parameter


        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        try {
            const response = await fetch(url, requestOptions); //
            const data = await response.json();
            if(response.ok) {
                setUserWallData({
                    communityMessages: data.communityMessages,
                    friendsMessages: data.friendsMessages,
                    userWallFeed: data.userWallFeed,
                    userDailyMessage: data.userDailyMessage,
                    userPostFlag: data.userPostFlag
                });

               
            } else {
                throw new Error('Failed to fetch wall feed');
            }
        } catch (error) {
            console.error('Error fetching wall feed:', error);
        }
    },[token, socialFilter]);

    useEffect(() => {
        fetchWallFeed(socialFilter);
    }, [fetchWallFeed, socialFilter]);
    
    
    const handleRefresh = (filter) => {
        fetchWallFeed(filter);
       
    };
    

    const handleSocialFilterChange = (filter) => {
        setSocialFilter(filter);
        
    }; 

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission behavior
    
        const requestOptions = {
            method: 'POST', // Change to POST for sending data
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ message: postText }) // Send the post text
        };
    
        try {
            const response = await fetch(`${API_URL}//handle-user-post`, requestOptions);
            const data = await response.json();
            if(response.ok) {
                // Handle successful submission
                
                // Optionally, clear the textarea and refresh the wall feed
                setPostText('');
                fetchWallFeed(); 
            } else {
                throw new Error('Failed to submit post');
            }
        } catch (error) {
            console.error('Error submitting post:', error);
        }
    };
    
    

    

    
    return (
        <div className="user-wall-feed-holding-container">
            <div className="user-controls-holding-container">
                <div className="user-wall-control-panel">
                    <div className="control-boxes">
                        <div className="control-box">
                            <div className="control-header">Community</div>
                            <div className="value-count">{userWallData.communityMessages} messages</div>
                            <button 
                                className={`filter-button ${socialFilter === "community" ? "active" : ""}`}
                                onClick={() => handleSocialFilterChange("community")}>
                                {socialFilter === "community" ? "Selected" : "Select"}
                            </button>
                        </div>
                        <div className="control-box">
                            <div className="control-header">Friends</div>
                            <div className="value-count">{userWallData.friendsMessages} messages</div>
                            <button 
                                className={`filter-button ${socialFilter === "friends" ? "active" : ""}`}
                                onClick={() => handleSocialFilterChange("friends")}>
                                {socialFilter === "friends" ? "Selected" : "Select"}
                            </button>
                        </div>
                    </div>

                    <div className="user-wall-posting-card">
                        {userWallData.userPostFlag === 1 ? (
                            <UserSocialPost 
                                username={userWallData.userDailyMessage?.USERNAME}
                                currentLevel={userWallData.userDailyMessage?.CURRENT_LEVEL}
                                datetime={userWallData.userDailyMessage?.DATETIME} 
                                message={userWallData.userDailyMessage?.MESSAGE}
                            />
                        ) : (
                            <div className="user-wall-posting-input">
                                
                                    <div className="user-wall-posting-input-header">
                                        Send out your daily message!   
                                    </div>
                                    <div className="user-wall-posting-input-sub-header">
                                        This cannot be edited, make it count! 
                                    </div>
                                    <div className='user-daily-post-input-box'>
                                        <textarea
                                            className='post-input'
                                            placeholder='Write your post here...'
                                            value={postText} // Use the postText state as the value
                                            onChange={handleInputChange}
                                            maxLength="140"
                                        ></textarea>
                                        <div className="character-counter">
                                            {charCount}/140 Characters
                                        </div>
                                        <button type="submit" className='post-button' onClick={handleSubmit}>Post!</button>
                                    </div>
                                
                            </div>
                        )}
                    </div>
                
                    <button className="control-box refresh-button" onClick={() => handleRefresh(socialFilter)}>Refresh Your Wall!</button>
                </div>
            </div>
            

            <div className="user-wall-social-feed">
                {userWallData.userWallFeed.map((post) => (
                    <UserSocialPost 
                        key={post.ID}
                        username={post.USERNAME}
                        currentLevel={post.CURRENT_LEVEL}
                        datetime={post.DATETIME}
                        message={post.MESSAGE}
                    />
                ))}
            </div>


        </div>
    );
};

export default UserWallFeed;
