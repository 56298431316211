import React from 'react';
import './SelectBox.css';

const SelectBox = ({ title, isSelected, onFilterChange, valueCount, valueHeader }) => {
    return (
        <div className="select-box">
            <div className="select-box-header">{title}</div>
            <div className="select-box-value-count"> {valueCount} {valueHeader}</div>
            <button 
                className={`select-box-filter-button ${isSelected ? "active" : ""}`}
                onClick={() => onFilterChange(title.toLowerCase())}>
                {isSelected ? "Selected" : "Select"}
            </button>
        </div>
    );
};

export default SelectBox;
