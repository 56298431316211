import React, { useEffect, useState } from 'react';
import NavBar from '../components/Fixed/NavBar';
import Footer from '../components/Fixed/Footer';
import UserProfile from '../components/UserProfilePage/UserProfileParent';
import { useParams } from 'react-router-dom';
import pagePayload from './pagePayload';

function UserProfilePage() {
  const { username } = useParams();
  const [staticUsername, setStaticUsername] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const data = await pagePayload();
      setStaticUsername(data.username);
    };

    fetchData();
  }, []);

  return (
    <div className="landing-page">
      <NavBar username={staticUsername} />
      <UserProfile username={username} />
      <Footer />
    </div>
  );
}

export default UserProfilePage;
