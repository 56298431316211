import React from 'react';
import Select from 'react-select';
import './AdvancedFilterModal.css';

const AdvancedFilterModal = ({ countryOptions, birthstoneOptions, workfieldOptions, onApplyFilters, selectedCountries, setSelectedCountries, selectedBirthstones, setSelectedBirthstones, selectedWorkfields, setSelectedWorkfields }) => {
    
    

    const formatOptions = (options) => options.map(option => ({ value: option, label: option }));

    const handleCountryChange = (selectedOptions) => {
        setSelectedCountries(selectedOptions);
    };

    const handleBirthstoneChange = (selectedOptions) => {
        setSelectedBirthstones(selectedOptions);
    };

    const handleWorkfieldChange = (selectedOptions) => {
        setSelectedWorkfields(selectedOptions);
    };

    const selectAll = () => {
        setSelectedCountries([]);
        setSelectedBirthstones([]);
        setSelectedWorkfields([]);
    };

    

    const customSelectStyles = {
        input: (provided) => ({
            ...provided,
            caretColor: 'transparent', // Hides the caret (typing cursor)
        }),
    };

    return (
        <div className='advanced-filter-modal-holding-container'>
            <div className='filter-container'>
                <label className='filter-label'>Country:</label>
                <Select
                    isMulti
                    options={formatOptions(countryOptions)}
                    value={selectedCountries}
                    onChange={handleCountryChange}
                    styles={customSelectStyles}
                />
            </div>
            
            <div className='filter-container'>
                <label className='filter-label'>Birthstone:</label>
                <Select
                    isMulti
                    options={formatOptions(birthstoneOptions)}
                    value={selectedBirthstones}
                    onChange={handleBirthstoneChange}
                    styles={customSelectStyles}
                />
            </div>
            
            <div className='filter-container'>
                <label className='filter-label'>Workfield:</label>
                <Select
                    isMulti
                    options={formatOptions(workfieldOptions)}
                    value={selectedWorkfields}
                    onChange={handleWorkfieldChange}
                    styles={customSelectStyles}
                />
            </div>

            <div className='button-container'>
                <button onClick={selectAll}>Clear Filters</button>
                
            </div>
        </div>
    );
};

export default AdvancedFilterModal;
