import React from 'react';
import './COLOREDUserMetricDisplay.css'; // Assume you have corresponding CSS file for styling

const UserMetricDisplay = ({ winPercent, pointsEarned, challengesCompleted, currentStreak, colorValue }) => {
  return (
    <div className="user-metric-display">
      <div className="stat-box" style={{borderColor: colorValue}}>
        <div className="stat-value">{(winPercent * 100).toFixed(0)}%</div>
        <div className="stat-label">Average Win Percent</div>
      </div>
      <div className="stat-box" style={{borderColor: colorValue}} >
        <div className="stat-value">{pointsEarned}</div>
        <div className="stat-label">Points Earned</div>
      </div>
      <div className="stat-box" style={{borderColor: colorValue}}>
        <div className="stat-value">{challengesCompleted}</div>
        <div className="stat-label">Challenges Completed</div>
      </div>
      <div className="stat-box" style={{borderColor: colorValue}}>
        <div className="stat-value">{currentStreak}</div>
        <div className="stat-label">Current Streak</div>
      </div>
    </div>
  );
};

export default UserMetricDisplay;
