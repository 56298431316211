import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './DailyChallengeModule.css';

import { config } from '../../config'; // Adjust the path as needed

const API_URL = config.API_URL;

const DailyChallengeModule = ({ initialChallenges }) => {

  const [challenges, setChallenges] = useState(initialChallenges || []);

  

  // Function to get the auth token from local storage
  const getAuthToken = () => localStorage.getItem('token');

  // Function to fetch the latest challenges
  const fetchChallenges = async () => {
    try {
      const token = getAuthToken();
      const response = await axios.get(`${API_URL}/fetch-challenges`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setChallenges(response.data);
    } catch (error) {
      console.error('Error fetching challenges:', error.response?.data?.error || error.message);
    }
  };

  // Function to accept a challenge
  const acceptChallenge = async (challengeId) => {
    try {
      
      const token = getAuthToken();
      const response = await axios.post(`${API_URL}/update-challenge-status`, { challengeId }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      fetchChallenges(); // Re-fetch the challenges to update the UI
    } catch (error) {
      console.error('Error accepting challenge:', error.response?.data?.error || error.message);
    }
  };

  useEffect(() => {
    
    setChallenges(initialChallenges); // Update the state when initialChallenges changes
  }, [initialChallenges]); // Dependency array, re-run this effect when initialChallenges changes

  

  return (
    <div className='challenge-holding-container'>
      <div className="challenge-container">
        {challenges.map((challenge) => (
          <div key={challenge.CHALLENGE_ID} className={`challenge ${challenge.CHALLENGE_DIFFICULTY}`}>
            <span className="challenge-desc">{challenge.CHALLENGE_DESCP}</span>
            <span className="challenge-points">{challenge.CHALLENGE_POINTS} Points</span>
            <button 
              className={`challenge-button ${challenge.CHALLENGE_STATUS ? 'button-off' : 'button-on'}`} 
              onClick={() => acceptChallenge(challenge.CHALLENGE_ID)}
            >
              {challenge.CHALLENGE_STATUS ? 'Complete!' : 'Turn in!'}
            </button>

          </div>
        ))}
      </div>
    </div>
  );
};

export default DailyChallengeModule;
