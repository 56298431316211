import React from 'react';
import { SimulationChart } from './SimulationChart';
import './Simulation.css'

const Simulation = () => {
  return (
    <div className='graph-container'>
      <h1 className='header'>An application designed to build life altering habits </h1>
      <h3 className='middleheader'>Deviate from <span className='colored-average'>Average.</span></h3>
      <h2 className='subheader'>Unleash Your Inner <span className='colored-champion'>Champion.</span></h2>
      <SimulationChart />
    </div>
  );
};

export default Simulation;
