import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './PasswordReset.css';
import { config } from '../../config'; // Adjust the path as needed


const PasswordReset = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const API_URL = config.API_URL;

  // Extract the token from the URL
  const { token } = useParams();

  

  const resetPassword = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/password-reset/${token}`, { password });
      setSuccess(response.data.message);
      setError('');
    } catch (errorResponse) {
      setError(errorResponse.response?.data?.error || 'An unexpected error occurred.');
      setSuccess('');
    }
  };

  return (
    <div className="password-reset-container">
      <form onSubmit={resetPassword}>
        <input
          type="password"
          placeholder="New Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="Confirm New Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <button type="submit">Reset Password</button>
      </form>
      {error && <div className="error-message">{error}</div>}
      {success && <div className="success-message">{success}</div>}
    </div>
  );
};

export default PasswordReset;
