import React, { useState, useEffect } from 'react';
import NavBar from '../components/Fixed/NavBar';
import Footer from '../components/Fixed/Footer';
import UserMessageCard from '../components/HomePage/UserMessageCard';
import RonaldMessageCard from '../components/HomePage/RonaldMessageCard';
import DailyChallengeModule from '../components/HomePage/DailyChallengeModule';
import UserProfileCard from '../components/HomePage/UserProfileCard/UserProfileCard';
import fetchHomePayload from '../components/HomePage/Payload'

function HomePage() {
  const [payload, setPayload] = useState({
    messageFromRonald: {},
    randomMessages: [],
    challenges: [],
    userPayload: {
      weeklyPoints: 0,
      weeklyChallenges: 0,
      averageWinPercent: 0,
      currentStreak: 0,
      currentXP: 0,
      username: '',
      currentLevel: 0
    },
    data: []
  });

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchHomePayload();
      if (data) {
        setPayload(prevPayload => ({
          ...prevPayload, 
          messageFromRonald: data.messageFromRonald || {},
          randomMessages: data.randomMessages || [],
          challenges: data.challenges || [],
          userPayload: {
            ...prevPayload.userPayload,
            ...data.userPayload
          },
          data: data.data
        }));
      }
    };

    fetchData();
  }, []);


  return (
    <div className="landing-page">
      <NavBar username={payload.userPayload.username}/>
      {payload.messageFromRonald && (
        <RonaldMessageCard
          username={payload.messageFromRonald.USERNAME}
          datetime={payload.messageFromRonald.DATETIME}
          message={payload.messageFromRonald.MESSAGE}
          currentLevel={payload.messageFromRonald.CURRENT_LEVEL}
        />
      )}

      {payload.randomMessages.length > 0 && payload.randomMessages.map((message, index) => (
        <UserMessageCard
          key={index}
          username={message.USERNAME}
          datetime={message.DATETIME}
          message={message.MESSAGE}
          currentLevel={message.CURRENT_LEVEL}
        />
      ))}

      {/* Inserting the DailyChallengeModule */}
      <DailyChallengeModule initialChallenges={payload.challenges}/>

      <UserProfileCard 
      username={payload.userPayload.username} 
      currentLevel={payload.userPayload.currentLevel}
      weeklyPoints={payload.userPayload.weeklyPoints} 
      weeklyChallenges={payload.userPayload.weeklyChallenges}
      averageWinPercent={payload.userPayload.averageWinPercent} 
      currentStreak={payload.userPayload.currentStreak}
      currentXP={payload.userPayload.currentXP}
      dataForVisualization={payload.data}
      />

      <Footer />
    </div>
  );
}

export default HomePage;
