import React, { useState } from 'react';
import './UserAuth.css'
import { useNavigate } from 'react-router-dom';
import { registerUser, loginUser } from './API'; 


const ToggleButtonGroup = ({ isRegister, setIsRegister, resetForm, setError, setSuccessMessage }) => (
    <div className="buttonGroup">
        <button onClick={() => { setIsRegister(false); resetForm(); setError(''); setSuccessMessage(''); }}
                className={`authButton ${!isRegister ? "active" : ""}`}>
            SIGN IN
        </button>
        <button onClick={() => { setIsRegister(true); resetForm(); setError(''); setSuccessMessage(''); }}
                className={`authButton ${isRegister ? "active" : ""}`}>
            JOIN THE COMMUNITY!
        </button>
    </div>
);

const FormInput = ({ label, type, value, onChange }) => (
    <>
        <h2 className={`inputForm_${label.toLowerCase()}Header`}>{label}</h2>
        <input 
            type={type}
            className={`inputForm_${label.toLowerCase()}`}
            value={value}
            onChange={onChange}
        />
    </>
);

const SignInForm = ({ username, setUsername, password, setPassword, handleSubmit, navigate }) => {
    // const handleForgotPasswordClick = () => {
    //     navigate('/forgot-password');
    // };

    return (
        <form className='form' onSubmit={handleSubmit}>
            <FormInput label="Username" type="text" value={username} onChange={e => setUsername(e.target.value)} />
            <FormInput label="Password" type="password" value={password} onChange={e => setPassword(e.target.value)} />
            <button className='loginButton'>SIGN IN</button>
            {/* <h3 className='forgot-password' onClick={handleForgotPasswordClick}>Forgot Password?</h3> */}
        </form>
    );
};


const RegisterForm = ({ email, setEmail, username, setUsername, password, setPassword, confirmPassword, setConfirmPassword, handleSubmit, agreedToTerms, setAgreedToTerms, navigate }) => (
    
    <form className='form' onSubmit={handleSubmit}>
        
        <FormInput label="Username" type="text" value={username} onChange={e => setUsername(e.target.value)} />
        <FormInput label="Password" type="password" value={password} onChange={e => setPassword(e.target.value)} />
        <FormInput label="Confirm-Password" type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
        {/* <FormInput label="Email" type="email" value={email} onChange={e => setEmail(e.target.value)} /> */}
        
        <div className="terms-checkbox">
            <input 
                type="checkbox" 
                id="terms-checkbox-input"
                className="terms-checkbox-input" 
                checked={agreedToTerms} 
                onChange={e => setAgreedToTerms(e.target.checked)} 
            />
            <label htmlFor="terms-checkbox-input" className="terms-checkbox-label">
                I have read the <span className="terms-link" onClick={() => navigate('/terms-and-conditions')}>Terms and Conditions</span>
            </label>

            <p></p>
        </div>

        <button className='loginButton'>REGISTER</button>

    </form>
);


const UserAuth = () => {
    const navigate = useNavigate();
    const [isRegister, setIsRegister] = useState(false);
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState(''); // New state for success message
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');

    const resetForm = () => {
        setEmail('');
        setUsername('');
        setPassword('');
        setConfirmPassword('');
        setAgreedToTerms(false);
    };
    

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setSuccessMessage('');
        
    
        if (isRegister && !agreedToTerms) {
            setError("You must agree to the terms and conditions to register.");
            return;
        }

        if (isRegister && password !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }
    
        try {
            if (isRegister) {
                await registerUser({ email, username, password });
                setSuccessMessage("Account Created! Thank you so much for joining :) ");
                resetForm();
            } else {
                const data = await loginUser({ username, password });
                localStorage.setItem('token', data.token);
                navigate('/home');
            }
        } catch (err) {
            
            // Updated error handling for the new error message format
            setError(err.message || 'An unexpected error occurred. Please try again later.');
        }
    };
    
        
    
    

    return (
        <div className='card-three'>
            <div className="auth-container">
                <ToggleButtonGroup 
                    isRegister={isRegister} 
                    setIsRegister={setIsRegister} 
                    resetForm={resetForm}
                    setError={setError} // Pass setError to ToggleButtonGroup
                    setSuccessMessage={setSuccessMessage} // Pass setSuccessMessage to ToggleButtonGroup
                />
                <h1 className='greeting'>{isRegister ? "Ready for a challenge?" : "Welcome Back, My Friend!"}</h1>
                
                {isRegister ? (
                    <RegisterForm 
                        email={email} setEmail={setEmail}
                        username={username} setUsername={setUsername}
                        password={password} setPassword={setPassword}
                        handleSubmit={handleSubmit}
                        agreedToTerms={agreedToTerms} setAgreedToTerms={setAgreedToTerms}
                        navigate={navigate} // Pass navigate to RegisterForm
                        confirmPassword={confirmPassword} 
                        setConfirmPassword={setConfirmPassword}
                    />
                ) : (
                    <SignInForm 
                        username={username} setUsername={setUsername}
                        password={password} setPassword={setPassword}
                        handleSubmit={handleSubmit}
                        navigate={navigate} // Pass navigate to SignInForm
                    />
                )}
                
                {error && <div className='error-message'>{error}</div>}
                {successMessage && <div className='success-message'>{successMessage}</div>}
            </div>
        </div>
    );
};

export default UserAuth;
