import { config } from '../../../config'; // Adjust the path as needed

const API_URL = config.API_URL;

export const registerUser = async (userData) => {
  try {
    const response = await fetch(`${API_URL}/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      const errorData = await response.json();
      // Adjusting error handling to the new format
      
      throw new Error(errorData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const loginUser = async (userData) => {
  // The loginUser function remains unchanged as its error handling is presumably aligned with a different backend endpoint
  try {
    const response = await fetch(`${API_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};
