import React from "react";
import './GameWeekList.css';


import UncommonCrown from '../../assets/CrownRanks/UncommonCrown.png';
import CommonCrown from '../../assets/CrownRanks/CommonCrown.png';
import RareCrown from '../../assets/CrownRanks/RareCrown.png';
import EpicCrown from '../../assets/CrownRanks/EpicCrown.png';
import LegendaryCrown from '../../assets/CrownRanks/LegendaryCrown.png'
import MythicalCrown from '../../assets/CrownRanks/MythicalCrown.png'




const GameWeekList = () => {
    return (
        <div className="game-week-list-holding-container">

            <p className="game-week-header"> 
            
            The community is presented with a set of challenges  that are designed to be completed first thing in the morning. 
            </p>

            <p className="game-week-header"> 
            Each challenge rewards the user with points which are used to level up and progress their account.
            </p>

            <p className="game-week-header"> 
            The users who are the most <span style={{color: '#6A61B1'}}>consistent</span> will be the ones who <span style={{color: '#6A61B1'}}>set themselves apart</span> and realize <span style={{color: '#6A61B1'}}>quantified progress</span> in their respective journey.
            </p>


            <div className="crown-rank-holder">
                <div>
                    <img src={UncommonCrown} alt="Uncommon Crown" />
                    <p style={{color: "#A0A0A0"}}>Noobie</p>
                    <p style={{color: "#A0A0A0"}}>Level: 7</p>
                </div>
                <div>
                    <img src={CommonCrown} alt="Common Crown" />
                    <p style={{color: "#00C62C"}} >Competitor</p>
                    <p style={{color: "#00C62C"}} >Level: 28</p>
                </div>
                
            </div>

            <div className="crown-rank-holder">
                <div>
                    <img src={RareCrown} alt="Rare Crown" />
                    <p style={{color: "#0050C9"}}>Grinder</p>
                    <p style={{color: "#0050C9"}}>Level: 56</p>
                </div>
                <div>
                    <img src={EpicCrown} alt="Epic Crown" />
                    <p style={{color: "#5C01B7"}}>Contender</p>
                    <p style={{color: "#5C01B7"}}>Level: 140</p>
                </div>
                
            </div>

            <div className="crown-rank-holder">
                <div>
                    <img src={MythicalCrown} alt="Mythical Crown" />
                    <p style={{color: "#00A1E5"}}>Challenger</p>
                    <p style={{color: "#00A1E5"}}>Level: 224</p>
                </div>
                <div>
                    <img src={LegendaryCrown} alt="Legendary Crown" />
                    <p style={{color: "#FE8900"}}>Champion</p>
                    <p style={{color: "#FE8900"}}>Level: 308</p>
                </div>
                
            </div>

            <div className="day-card">
                <h1 className="day-of-week">Sunday</h1>
                <h2 className="day-of-week-name">Global Reset</h2>
                <h3 className="day-of-week-difficulty" style={{ color: '#659947' }}>Relaxed</h3>

                
                
                <p className="day-of-week-descp"> Reset back to your base settings! </p>
                <p className="day-of-week-descp"> Organize and Prepare for the week ahead! </p>
            </div> 

            <div className="day-card">
                <h1 className="day-of-week">Monday</h1>
                <h2 className="day-of-week-name">Magnificent Monday</h2>
                <h3 className="day-of-week-difficulty" style={{color: '#d6ac53'}}>Balanced</h3>
                
                <p className="day-of-week-descp"> Get back into your routine! </p>
                <p className="day-of-week-descp"> It's time to get after the week! </p>
            </div> 

            <div className="day-card">
                <h1 className="day-of-week">Tuesday</h1>
                <h2 className="day-of-week-name">Terrible Tuesday</h2>
                <h3 className="day-of-week-difficulty" style={{color: '#e83b46'}}>Challenging</h3>
                
                <p className="day-of-week-descp"> You should be warmed up by now! </p>
                <p className="day-of-week-descp"> Let's get after it! </p>
            </div> 

            <div className="day-card">
                <h1 className="day-of-week">Wednesday</h1>
                <h2 className="day-of-week-name">Werk It Wednesday</h2>
                <h3 className="day-of-week-difficulty" style={{color: '#e83b46'}} >Challenging</h3>
                
                <p className="day-of-week-descp"> You can't stop now!  </p>
                <p className="day-of-week-descp"> Keep going! Don't Stop! </p>
            </div> 

            <div className="day-card">
                <h1 className="day-of-week">Thursday</h1>
                <h2 className="day-of-week-name">Perfect Thursday</h2>
                <h3 className="day-of-week-difficulty" style={{color: '#d6ac53'}} >Balanced</h3>
                
                <p className="day-of-week-descp"> Let's start wrapping this week up! </p>
                <p className="day-of-week-descp"> Perfect execution! </p>
            </div> 

            <div className="day-card">
                <h1 className="day-of-week">Friday</h1>
                <h2 className="day-of-week-name">Freakin' Friday!</h2>
                <h3 className="day-of-week-difficulty" style={{ color: '#659947' }}>Relaxed</h3>
                
                <p className="day-of-week-descp"> It's freakin' Friday! </p>
                <p className="day-of-week-descp"> Time to finish this week strong! </p>
            </div> 

                
            <div className="day-card">
                <h1 className="day-of-week">Saturday</h1>
                <h2 className="day-of-week-name">Game Day!</h2>
                <h3 className="day-of-week-difficulty" style={{ color: '#659947' }} >Relaxed</h3>
                
                <p className="day-of-week-descp">Your week has prepared you for Game Day! </p>
                <p className="day-of-week-descp"> Relax and enjoy it! </p>
            </div> 




        </div>



    );
};

export default GameWeekList;