import React from 'react';
import './UserProfileMetrics.css'; // Assume you have corresponding CSS file for styling

const UserProfileMetrics= ({ averageWinPercent, lifetimePoints, lifetimeChallenges, daysSinceSignup, colorValue }) => {
  return (
    <div className="user-metric-display">
      <div className="stat-box" style={{borderColor:colorValue}}>
        <div className="stat-value">{(averageWinPercent * 100).toFixed(0)}%</div>
        <div className="stat-label">Average Win Percent</div>
      </div>
      <div className="stat-box" style={{borderColor:colorValue}}>
        <div className="stat-value">{lifetimePoints}</div>
        <div className="stat-label">Lifetime Points</div>
      </div>
      <div className="stat-box" style={{borderColor:colorValue}}>
        <div className="stat-value">{lifetimeChallenges}</div>
        <div className="stat-label">Lifetime Challenges</div>
      </div>
      <div className="stat-box" style={{borderColor:colorValue}}>
        <div className="stat-value">{daysSinceSignup}</div>
        <div className="stat-label">Days Since Signup</div>
      </div>
    </div>
  );
};

export default UserProfileMetrics;
