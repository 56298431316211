import HomePage from '../pages/HomePage';
import ForgotPassword from '../pages/ForgotPassword';
import PasswordReset from '../pages/ResetPassword';
import LandingPage from '../pages/LandingPage';
import PrivateRoute from '../middleware/PrivateRoute';
import PublicRoute from '../middleware/PublicRoute';
import UserProfilePage from '../pages/UserProfilePage';
import TheWall from '../pages/TheWall';
import Leaderboard from '../pages/LeaderBoard';
import AboutMe from '../pages/AboutMe';
import GameGuide from '../pages/GameGuide';
const routes = [
  {
    path: '/',
    element: <PublicRoute> <LandingPage /></PublicRoute>,
  },
  {
    path: '/forgot-password',
    element: <PublicRoute> <ForgotPassword /> </PublicRoute>,
  },
  {
    path: '/password-reset/:token',
    element: <PublicRoute> <PasswordReset /> </PublicRoute>,
  },
  {
    path: '/home',
    element: <PrivateRoute> <HomePage /> </PrivateRoute>,
  },
  {
    path: '/user-profile/:username',
    element: <PrivateRoute> <UserProfilePage /> </PrivateRoute>
  },
  {
    path: '/wall',
    element: <PrivateRoute> <TheWall /> </PrivateRoute>
  },
  {
    path: '/leaderboard',
    element: <PrivateRoute> <Leaderboard /> </PrivateRoute>
  },
  {
    path: '/game-guide',
    element: <PrivateRoute> <GameGuide /> </PrivateRoute>
  }

];

export default routes;
