import React, { useState } from 'react';
import './ForgotPassword.css';
import { config } from '../../config'; // Adjust the path as needed


const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const API_URL = config.API_URL;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setIsLoading(true);
    try {
      const response = await fetch(`${API_URL}/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      setIsLoading(false);
      if (response.ok) {
        const data = await response.text();
        
        setSubmitted(true);
      } else {
        const errorData = await response.text();
        setError(errorData);
      }
    } catch (err) {
      console.error('Fetch error', err);
      setError('Failed to send password reset email. Please try again later.');
      setIsLoading(false);
    }
  };

  return (
    <div className="container">
      {isLoading ? (
        <div className="loading-circle"></div>
      ) : !submitted ? (
        <form onSubmit={handleSubmit} className="form">
          {error && <p className='error-message'>{error}</p>}
          <input
            type="email"
            id="email"
            className="input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Enter your email"
          />
          <button type="submit" className="button">Send Reset Link</button>
        </form>
      ) : (
        <p className='success-message'>If we have your email on file, instructions for setting a new password have been sent to your email.</p>
      )}
    </div>
  );
};

export default ForgotPassword;
