import React from 'react';
import './COLOREDUserProfileCard.css';
import UserMetricDisplay from './UserMetricDisplay';
import UserStatDisplay from './UserStatDisplay';

import UserExperienceBar from './UserExperienceBar';

import UncommonCrown from '../../../assets/CrownRanks/UncommonCrown.png';
import CommonCrown from '../../../assets/CrownRanks/CommonCrown.png';
import RareCrown from '../../../assets/CrownRanks/RareCrown.png';
import EpicCrown from '../../../assets/CrownRanks/EpicCrown.png';
import LegendaryCrown from '../../../assets/CrownRanks/LegendaryCrown.png'
import MythicalCrown from '../../../assets/CrownRanks/MythicalCrown.png'


const COLOREDUserProfileCard = ({ username, weeklyPoints, weeklyChallenges, averageWinPercent, currentStreak, currentXP, dataForVisualization, currentLevel, colorValue }) => {
    let crownSrc;
    let colorClass;
    
   

    if (currentLevel <= 7) {
      crownSrc = null;
      colorClass = 'no-shade';
      
    } else if (currentLevel > 7 && currentLevel <= 28) {
      crownSrc = UncommonCrown;
      colorClass = 'uncommon-shade';
    } else if (currentLevel > 28 && currentLevel <= 56) {
      crownSrc = CommonCrown;
      colorClass = 'common-shade';
    } else if (currentLevel > 56 && currentLevel <= 140) {
      crownSrc = RareCrown;
      colorClass = 'rare-shade';
    } else if (currentLevel > 140 && currentLevel <= 224) {
      crownSrc = EpicCrown;
      colorClass = 'epic-shade';
    } else if (currentLevel > 224 && currentLevel <= 308) {
      crownSrc = MythicalCrown;
      colorClass = 'mythical-shade';
    } else if (currentLevel > 308) {
      crownSrc = LegendaryCrown;
      colorClass = 'legendary-shade';
    }



    return (
      <div className='colored-user-profile-card-holding-container'>
          <div className="colored-user-profile-card" style={{borderColor: colorValue}}>
              {/* Header added here */}
              <div className="colored-user-profile-card-header">
                  <h2 className='colored-user-profile-card-title ' style={{borderColor: colorValue}}>7-Day Performance Review</h2>
              </div>
  
              <div className="colored-user-profile-header">
                  {crownSrc && <img src={crownSrc} alt="Crown" className="crown-icon" />}
                  <span className={`username ${colorClass}`}>{username}</span>
              </div>
              
              <UserMetricDisplay
                  winPercent={averageWinPercent} /* Assuming this should be a percentage out of 100 */
                  pointsEarned={weeklyPoints}
                  challengesCompleted={weeklyChallenges}
                  currentStreak={currentStreak}
                  colorValue = {colorValue}
              />
  
              <UserStatDisplay data={dataForVisualization} currentLevel={currentLevel} />
  
              <UserExperienceBar currentLevel={currentLevel} currentXP={currentXP} />
              
          </div>
      </div>
  );
  
    
    
    
};

export default COLOREDUserProfileCard;
