import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';


const PrivateRoute = ({ children }) => {
    // Assuming the token name you're using is 'token' as you set it previously
    const token = localStorage.getItem('token');  

    let isAuthenticated = false;

    if (token) {
        try {
            // Decode the token to find out the expiry time
            const decoded = jwtDecode(token);
            // Check if the token has expired
            const currentTime = Date.now() / 1000;
            isAuthenticated = decoded.exp > currentTime;
        } catch (error) {
            // If error occurs during decoding, the token is invalid (or corrupted)
            isAuthenticated = false;
        }
    }

    // Navigate to the login page if not authenticated, else render the children components
    return isAuthenticated ? children : <Navigate to="/" replace />;
};

export default PrivateRoute;
