import React from 'react';
import { LineChart, Line, XAxis, YAxis } from 'recharts';

const UserStatDisplay = ({ data, currentLevel }) => {
    let lineColor;
   

    if (currentLevel <= 7) {
      lineColor = "#DCCACA"
      
    } else if (currentLevel > 7 && currentLevel <= 28) {
        lineColor = "#A0A0A0"
    }  else if (currentLevel > 28 && currentLevel <= 56) {
        lineColor = "#00C62C"
        
      } else if (currentLevel > 56 && currentLevel <= 140) {
        lineColor = "#0050C9"
        
      } else if (currentLevel > 140 && currentLevel <= 224) {
        lineColor = "#5C01B7"
      } else if (currentLevel > 224 && currentLevel <= 308) {
        lineColor = "#00A1E5"
      } else if (currentLevel > 308) {
        lineColor = "#FE8900"
      }

   

    return (
      
        <LineChart data={data} margin={{ top: 5, right: 30, bottom: 5, left: 0 }} width={300} height={300} style={{ marginRight: '40px' }}>
          <XAxis dataKey="DATE" />
          <YAxis domain={[0, 'dataMax + 0.1']} tick={false} label={{ value: 'Win Percent', angle: -90}}>
          </YAxis>
          
          <Line type="monotone" dataKey="WIN_PERCENT" stroke={lineColor} dot={false} />
        </LineChart>
    
    );
  };

export default UserStatDisplay;
