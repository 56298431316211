import React, { useEffect, useState } from 'react';
import NavBar from '../components/Fixed/NavBar';
import Footer from '../components/Fixed/Footer';
import LeaderboardParent from '../components/Leaderboard/LeaderboardParent';
import pagePayload from './pagePayload';


function Leaderboard() {

  const [staticUsername, setStaticUsername] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const data = await pagePayload();
      setStaticUsername(data.username);
    };

    fetchData();
  }, []);


  return (
    <div className="landing-page">
      <NavBar username={staticUsername}/>

      <LeaderboardParent />

    
      <Footer />
    </div>
  );
}

export default Leaderboard;
