import React from 'react';
import './UserListModal.css'; // You'll create this CSS file to style your modal
import { useNavigate } from 'react-router-dom';

import UncommonCrown from '../../assets/CrownRanks/UncommonCrown.png';
import CommonCrown from '../../assets/CrownRanks/CommonCrown.png';
import RareCrown from '../../assets/CrownRanks/RareCrown.png';
import EpicCrown from '../../assets/CrownRanks/EpicCrown.png';
import LegendaryCrown from '../../assets/CrownRanks/LegendaryCrown.png'
import MythicalCrown from '../../assets/CrownRanks/MythicalCrown.png'


const UserListModal = ({ isOpen, users, onClose }) => {
  const navigate = useNavigate();

  const handleUserClick = (username) => {
    onClose();
    navigate(`/user-profile/${username}`);
  };

  if (!isOpen) {
    return null;
  }

  let crownSrc;
  let colorValue;
  

  const getCrownAndColor = (currentLevel) => {
    // Payload using username to find currentLevel
    if (currentLevel <= 7) {
      crownSrc = null;
      colorValue = '#DCCACA';
    } else if (currentLevel > 7 && currentLevel <= 28) {
      crownSrc = UncommonCrown;
      colorValue = '#A0A0A0';

    } else if (currentLevel > 28 && currentLevel <= 56) {
      crownSrc = CommonCrown;
      colorValue = '#00C62C';
    } else if (currentLevel > 56 && currentLevel <= 140) {
      crownSrc = RareCrown;
      colorValue = '#0050C9';
    } else if (currentLevel > 140 && currentLevel <= 224) {
      crownSrc = EpicCrown;
      colorValue = '#5C01B7'
    } else if (currentLevel > 224 && currentLevel <= 308) {
      crownSrc = MythicalCrown;
      colorValue = '#00A1E5'
    } else if (currentLevel > 308) {
      crownSrc = LegendaryCrown;
      colorValue = '#FE8900'
    }

    
    return { crownSrc, colorValue };
  };

  
  

    

  return (
    <div className="modal-backdrop">
      <button className="modal-close-btn" onClick={onClose}>X</button>
      
      <div className="modal">
        <ul className="user-list">
          {users.map(user => {
            const { crownSrc, colorValue } = getCrownAndColor(user.currentLevel);
  
            return (
              <li key={user.userId} style={{ color: colorValue, cursor: 'pointer' }} onClick={() => handleUserClick(user.username)}>
                {crownSrc && <img src={crownSrc} alt="Crown" className="user-crown" />}
                <span style={{  }}>{user.username}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
  
};

export default UserListModal;
