import React, { useState } from 'react';
import { LineChart, XAxis, YAxis, ReferenceLine, Line } from 'recharts';
import { useSimulationLogic } from './SimulationLogic';
import './SimulationChart.css'


export const SimulationChart = () => {
    const [input_discipline] = useState(0);
    const [input_focus] = useState(0);
    const { simulationData } = useSimulationLogic(1000, input_discipline, input_focus);

    return (
        <div className='simulationchart'>
            <LineChart width={375} height={400} data={simulationData}>
                <XAxis dataKey="simulationNumber" tick={false} label='Time'/>
                <YAxis domain={[0, 25]} tick={false} label={{ value: 'Daily Score', angle: -90}} />
                <ReferenceLine y={24} stroke="#3D3799" label="24 Hours" />
                <Line type="monotone" dataKey="minPerceivedValue" stroke="#DCCACA" name="Average" />
                <Line type="monotone" dataKey="maxPerceivedValue" stroke="#6A61B1" name="Champion" />
            </LineChart>
        </div>
    );
};
