import React from 'react';
import { useNavigate } from 'react-router-dom';
import './UserMessageCard.css'; // Assuming you have a CSS file for styling


import UncommonCrown from '../../assets/CrownRanks/UncommonCrown.png';
import CommonCrown from '../../assets/CrownRanks/CommonCrown.png';
import RareCrown from '../../assets/CrownRanks/RareCrown.png';
import EpicCrown from '../../assets/CrownRanks/EpicCrown.png';
import LegendaryCrown from '../../assets/CrownRanks/LegendaryCrown.png'
import MythicalCrown from '../../assets/CrownRanks/MythicalCrown.png'



// MessageCard component for individual messages
const UserMessageCard = ({ username, datetime, message, currentLevel, colorValue }) => {
  let crownSrc;
  let colorClass;


  if (currentLevel <= 7) {
    crownSrc = null;
    colorClass = 'no-shade';
  } else if (currentLevel > 7 && currentLevel <= 28) {
    crownSrc = UncommonCrown;
    colorClass = 'uncommon-shade';
  } else if (currentLevel > 28 && currentLevel <= 56) {
    crownSrc = CommonCrown;
    colorClass = 'common-shade';
  } else if (currentLevel > 56 && currentLevel <= 140) {
    crownSrc = RareCrown;
    colorClass = 'rare-shade';
  } else if (currentLevel > 140 && currentLevel <= 224) {
    crownSrc = EpicCrown;
    colorClass = 'epic-shade';
  } else if (currentLevel > 224 && currentLevel <= 308) {
    crownSrc = MythicalCrown;
    colorClass = 'mythical-shade';
  } else if (currentLevel > 308) {
    crownSrc = LegendaryCrown;
    colorClass = 'legendary-shade';
  }

  const navigate = useNavigate();

  const handleUsernameClick = () => {
    navigate(`/user-profile/${username}`)
  }
  
  
  const cardStyle = colorValue ? { borderColor: colorValue } : {};

  return (
    <div className='user-message-container'>
      <div className={`message-card ${colorClass}`} style={cardStyle}>
        <div className="message-header">
          <div className="header-left">
            {crownSrc && <img src={crownSrc} alt="Crown" className="crown-icon" />}
            <span className={`username ${colorClass}`} onClick={handleUsernameClick}>{username}</span>
          </div>
          <span className={`datetime ${colorClass}`}>{new Date(datetime).toLocaleString()}</span>
        </div>
        <div className="message-content">{message}</div>
        <div className="message-footer">
          <span className={`level ${colorClass}`}>Level: {currentLevel}</span>
        </div>
      </div>
    </div>
  );
};

export default UserMessageCard;
