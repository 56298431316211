import React from 'react';
import './RonaldMessageCard.css'; // Assuming you have a CSS file for styling
import RonaldAvatar from '../../assets/Avatars/RonaldAvatar.png';
import { useNavigate } from 'react-router-dom';

// MessageCard component for individual messages
const RonaldMessageCard = ({ username, datetime, message }) => {
  const navigate = useNavigate();

  const handleUsernameClick = () => {
    navigate(`/user-profile/ronald`);
  };

  const formattedDatetime = datetime ? new Date(datetime).toLocaleString() : new Date().toLocaleString();

  const messageContent = message ? message : "No message posted yet.";

  return (
    <div className='ronald-message-container'>
      <div className={`ronald-message-card`}>
        <div className="ronald-message-header">
          <img src={RonaldAvatar} alt='Ronald Avatar' className='ronald-avatar'></img>
          <h1> Today's Daily Message from <span className={`ronald-username`} onClick={handleUsernameClick}>Ronald</span></h1>
          <span className={`ronald-datetime`}>{formattedDatetime}</span>
        </div>
        <div className="ronald-message-content">{messageContent}</div>
      </div>
    </div>
  );
};

export default RonaldMessageCard;
